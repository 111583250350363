var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.messageOk.length > 0)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.messageOk.message)+" ")]):_vm._e(),(_vm.messageKo.length > 0)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.messageKo.message)+" ")]):_vm._e(),(_vm.isRoleAdmin)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('h2',{staticClass:"primary--text"},[_vm._v(" Liste des utilisateurs ")]),_c('basic-btn',{staticClass:"ml-4",attrs:{"icon":"mdi-folder-download-outline"},on:{"click":function($event){return _vm.download()}}},[_vm._v(" Exporter ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"items-per-page":15,"loading":_vm.loading,"loading-text":"Chargement des données. Veuillez patienter..."},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.users.indexOf(item) + 1)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleDateString('fr-FR'))+" ")])]}},{key:"item.finess",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.finess))])]}},{key:"item.isVerified",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.isVerified),callback:function ($$v) {_vm.$set(item, "isVerified", $$v)},expression:"item.isVerified"}})]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-light"},[_vm._v(_vm._s(item.role))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.checkMail(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-underline-circle ")])]}}],null,true)},[_c('span',[_vm._v("Forcer la vérification de l'email")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""},on:{"click":function($event){return _vm.resendMail(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-email-send ")])]}}],null,true)},[_c('span',[_vm._v("Renvoyer un email de changement de mot de passe")])])]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogValidate),callback:function ($$v) {_vm.dialogValidate=$$v},expression:"dialogValidate"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('v-spacer'),_vm._v(" Forcer la vérification de l'email "),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',{staticClass:"mx-0 text--primary",attrs:{"align":"center"}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"warning"}},[_vm._v(" Passer l'étape de vérfication de l'adresse email enlève la garantie que l'officine a bien accès aux mails envoyés par l'application. ")]),_c('p',{staticClass:"body-1 mb-1"},[_vm._v(" Êtes-vous sûr de vouloir forcer la vérification de l'adresse email suivante : ")]),_c('div',{staticClass:"body-1 mx-auto font-weight-light"},[_vm._v(" "+_vm._s(_vm.itemEmail)+" ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('basic-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Annuler")]),_c('v-spacer'),_c('basic-btn',{attrs:{"color":"green","dark":""},on:{"click":_vm.checkMailConfirm}},[_vm._v("Valider")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }