<template>
  <div>
    <v-alert v-if="messageOk.length > 0" type="success">
      {{ messageOk.message }}
    </v-alert>

    <v-alert v-if="messageKo.length > 0" type="error">
      {{ messageKo.message }}
    </v-alert>

    <v-card class="elevation-0" v-if="isRoleAdmin">
      <v-card-title>
        <h2 class="primary--text">
          Liste des utilisateurs
        </h2>

        <basic-btn
          class="ml-4"
          icon="mdi-folder-download-outline"
          @click="download()"
        >
          Exporter
        </basic-btn>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :items-per-page="15"
      class="elevation-0"
      :loading="loading"
      loading-text="Chargement des données. Veuillez patienter..."
    >
      <template v-slot:item.index="{ item }">
        <div class="text--secondary">
          {{ users.indexOf(item) + 1 }}
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div>
          {{ new Date(item.createdAt).toLocaleDateString('fr-FR') }}
        </div>
      </template>

      <template v-slot:item.finess="{ item }">
        <div class="font-weight-medium">{{ item.finess }}</div>
      </template>

      <template v-slot:item.isVerified="{ item }">
        <v-simple-checkbox
          v-model="item.isVerified"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.role="{ item }">
        <div class="font-weight-light">{{ item.role }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="checkMail(item)"
              class="mr-2"
              medium
            >
              mdi-check-underline-circle
            </v-icon>
          </template>
          <span>Forcer la vérification de l'email</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="resendMail(item)"
              class=""
              medium
            >
              mdi-email-send
            </v-icon>
          </template>
          <span>Renvoyer un email de changement de mot de passe</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogValidate" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          Forcer la vérification de l'email
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row align="center" class="mx-0 text--primary">
            <v-alert dense outlined type="warning">
              Passer l'étape de vérfication de l'adresse email enlève la
              garantie que l'officine a bien accès aux mails envoyés par
              l'application.
            </v-alert>
            <p class="body-1 mb-1">
              Êtes-vous sûr de vouloir forcer la vérification de l'adresse email
              suivante :
            </p>

            <div class="body-1 mx-auto font-weight-light">
              {{ itemEmail }}
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <basic-btn color="grey darken-1" text @click="closeDelete"
            >Annuler</basic-btn
          >
          <v-spacer></v-spacer>

          <basic-btn color="green" @click="checkMailConfirm" dark
            >Valider</basic-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import User from '../../../models/user'
import UserService from '../../../services/user.service'
import PasswordService from '../../../services/password.service'

export default {
  data: () => ({
    users: [],
    loading: true,
    dialogValidate: false,
    itemToValidate: null,
    itemIndexToValidate: -1,
    itemEmail: '',
    messageKo: '',
    messageOk: '',
    search: ''
  }),

  components: {
    'basic-btn': () => import('../../../core/BasicBtn')
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    if (this.isRoleAdmin) {
      UserService.getUsers().then(
        response => {
          this.loading = false
          this.users = response.data.users
        },
        error => {
          this.loading = false
          // eslint-disable-next-line no-console
          // console.log(error.response)
        }
      )
    }
  },

  computed: {
    headers() {
      if (this.isRoleAdmin) {
        return [
          { text: '', value: 'index', sortable: false },

          { text: "Date d'inscription", value: 'createdAt' },
          { text: 'Finess', value: 'finess' },
          { text: 'Adresse email', value: 'email' },
          { text: 'Email vérifié', value: 'isVerified' },
          { text: 'Code postal', value: 'postal' },
          { text: 'Role', value: 'role' },
          {
            text: '',
            value: 'actions',
            sortable: false,
            align: 'right',
            width: '100px'
          }
        ]
      }
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    isRoleAdmin() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  methods: {
    download() {
      if (this.isRoleAdmin) {
        UserService.getExport()
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/*' })
            let link = document.createElement('a')
            let label =
              'export-data-' + new Date().toLocaleDateString('fr-FR') + '.xlsx'
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .catch(err => {
            this.$toasted.show(err.error, {
              type: 'error',
              position: 'top-center',
              action: {
                text: 'Cancel',
                duration: '5000',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          })
      }
    },
    checkMail(item) {
      this.itemToValidate = item._id
      this.itemEmail = item.email
      this.itemIndexToValidate = this.users.indexOf(item)
      this.dialogValidate = true
    },
    checkMailConfirm() {
      let itemIndexToValidate = this.itemIndexToValidate
      UserService.verify(this.itemToValidate).then(
        response => {
          this.messageOk = response.data
          this.users[itemIndexToValidate].isVerified = true
        },
        error => {
          this.messageKo = error.data
        }
      )
      this.closeDelete()
    },
    closeDelete() {
      this.$nextTick(() => {
        this.dialogValidate = false
        this.itemToValidate = ''
        this.itemIndexToValidate = ''
      })
    },
    resendMail(item) {
      PasswordService.putEmail(item.email).then(
        response => {
          console.log(response.data)

          this.$toasted.show(response.data.message, {
            type: response.status === 200 ? 'success' : 'error',
            duration: '5000',
            position: 'top-center'
          })
        },
        error => {
          console.error(error.data)
          this.$toasted.show(
            'Une erreur est survenue lors du traitement de votre requête.',
            {
              type: 'error',
              duration: '5000',
              position: 'top-center'
            }
          )
        }
      )
    }
  }
}
</script>
